import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {BasePageProps, PuzzleScore, TaskType} from "../../../../../common/types";
import styled from "styled-components";
import Header from "../../../../framework/Header/header";
import Color from "../../../../../common/classes/Color";
import Button from "../../../../modules/Button/button";
import {useParams, useRouteMatch} from "react-router-dom";
import TaskModule from "../../../../modules/Task/task";
import ResultCard from "../../../../modules/ResultCard/resultcard";
import useLocationBlocker from "../../../../hooks/useLocationBlocker";
import Score from "../../../../../common/classes/Score";
import Footer from "../../../../framework/Footer/footer";
import {Store} from "../../../../../Store";
import Settings from "../../../../../common/Settings";
import Position from "../../../../../common/classes/Position";
import LabelButton from '../../../../modules/LabelButton/labelbutton';
import PersistentStore from "../../../../../common/classes/PersistentStore";
import ShadowContainer from "../../../../framework/ShadowContainer/shadowcontainer";

export interface TaskProps extends BasePageProps {
    media?: string;
    question?: string;
    taskType?: TaskType;
}

const Container = styled.main<{ bgColor?: string }>`
  height: 100%;
  background: ${({bgColor}) => bgColor};
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  margin: 25px 0;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .image > div:first-of-type {
    background-color: #000000;
  }

  &.buttons .labels {
    display: flex;
    justify-content: space-between;
    width: 311px;

    > h5 {
      flex: 1;
      text-align: center;
    }

    > h5:nth-last-child(2):first-child {
      font-size: 1em;
      text-align: left;
    }

    > h5:nth-child(n+2) {
      font-size: 1em;
      text-align: right;
    }
  }

  &.text > div {
    padding: 20px;
    background-color: #FFFF;
    font-weight: 700;
    font-size: 16px;
  }

  &.buttons > div {
    margin-bottom: 25px;
  }
`;

const PageHeader = styled.div`
  margin: 30px 0 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  > h1 {
    font-weight: 900;
    font-size: 16px;
    text-transform: uppercase;
  }
`;
const HintOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fade-in 200ms ease forwards;
  opacity: 1;
  @keyframes fade-in {
  0% { 
      background: transparent;
    opacity: 0;
    }
  100% { 
      background: rgba(0,0,0,0.3);
      opacity: 1;
    }
  } 
`;

const HintBox = styled.div`
  background: #FFFFFF;
  padding: 1.5em 2em;
  width: 350px;
  display: flex;
  flex-direction: column;

  > h5 {
    text-align: center;
    font-weight: 700;
    font-size: 1.25em;
    margin: 0.5em 0;
  }

  > p {
    font-size: 1.25em;
    margin: 0.5em 0;
  }

  > div:last-of-type {
    margin-top: 3em;

    button {
      flex: 1;
      display: flex;
      text-transform: revert;
      justify-content: space-around;
    }
  }

`;

const ResultOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
`;

const Task: React.FC<TaskProps> = ({bgColor, toggleCloseMenu, scoreCard, registerTask}) => {
    const {state} = useContext(Store);
    const {puzzles} = state;
    const {puzzleId, postId, taskId}: { [k: string]: string } = useParams<any>();
    const [hintActive, setHintActive] = useState(false);
    const [hintUsed, setHintUsed] = useState(false);
    const {url} = useRouteMatch();
    const history = useLocationBlocker();
    const [score, setScore] = useState<number | null>();
    const [checkedForValidPost, setCheckedForValidPost] = useState<boolean>(false);
    const {
        task,
        haveNextTask,
        currentPuzzle,
        currentPost,
        realTaskId,
        numberOfRealTasks
    }: any = useMemo(() => {
        if (!(puzzleId && postId && taskId && puzzles)) return undefined;
        const puzzle = puzzles?.find(({id}) => id && id === parseInt(puzzleId));
        const tasks = puzzle?.posts?.[parseInt(postId) - 1]?.tasks;
        const posts = puzzle?.posts;
        return {
            task: tasks ? tasks?.[parseInt(taskId) - 1] : {},
            haveNextTask: taskId < (tasks ?? []).length,
            numberOfTasks: tasks?.length ?? 0,
            numberOfRealTasks: tasks?.filter((_task: any) => _task?.taskType !== 'context').length,
            realTaskId: tasks?.slice(0, parseInt(taskId) - 1)?.filter((_task: any) => _task?.taskType !== 'context').length + 1,
            currentPuzzle: puzzle ?? {},
            currentPost: posts?.[parseInt(postId) - 1]
        };
    }, [puzzleId, postId, taskId, puzzles])

    const haveRemainingPosts = useMemo(() => {
        if (!(scoreCard && puzzleId && puzzles)) return true;
        let puzzle = scoreCard.puzzles.find(({id}: PuzzleScore) => id === parseInt(puzzleId));
        if (!puzzle) return false;
        return !Score.isAllPostsComplete(puzzle);
    }, [puzzles, scoreCard, puzzleId]);

    const highScoreScore = useMemo(() => {
        if (!(scoreCard && url)) return 0;
        return Score.parseScoreByUrl(scoreCard, url);
    }, [scoreCard, url])

    const isContext = task?.taskType === 'context';
    const isPersonalityTest = task?.taskType === 'personalityTest';

    const isCompleted = useMemo(() => {
        if (!(scoreCard && currentPost?.id && currentPuzzle?.id && taskId)) return false;
        let isDone = false;
        try {
            scoreCard.puzzles.forEach((puzzle) => {
                puzzle?.id === currentPuzzle?.id && puzzle.posts.forEach((post) => {
                    if (post?.id === currentPost?.id) {
                        let idx = parseInt(taskId) - 1;
                        isDone = post.tasks[idx].isTask && post.tasks[idx].completed;
                    }
                })
            })
        } catch (err) {
            console.warn("Can't check if task is completed. Err: ", err);
        }
        return isDone;
    }, [scoreCard, currentPuzzle, currentPost, taskId])

    // valid by presence and sequential
    const isValidPost: boolean | undefined = useMemo(() => {
        if (!(url && scoreCard && postId && state && currentPuzzle && puzzleId && puzzles)) return undefined;
        const puzzleScore = scoreCard.puzzles.find((_puzzle: PuzzleScore) => _puzzle.id === currentPuzzle.id);
        const postScore = puzzleScore?.posts?.[parseInt(postId) - 1];
        try {
            if (currentPuzzle?.requirePresence) {
                let postPosition: Position = currentPuzzle.posts?.[parseInt(postId) - 1]?.position;
                let dist = Position.calcDistance(state?.position, postPosition)
                if (typeof dist !== 'number' || dist > Settings.PRESENCE_DISTANCE) return false;
            }
            if (!(puzzleScore && postScore)) return true;
            return !(currentPuzzle?.sequential && !Score.isPostFirstSequential(puzzleScore, postScore));
        } catch (err) {
            return true;
        }
    }, [scoreCard, url, postId, state, puzzleId, puzzles, currentPuzzle]);

    const invalidScoreCard: boolean | undefined = useMemo(() => {
        if (!(puzzleId)) return undefined;
        const puzzleScore = scoreCard?.puzzles?.find((puzzle: PuzzleScore) => puzzle?.id === parseInt(puzzleId));
        return !puzzleScore || puzzleScore?.posts?.length === 0;
    }, [scoreCard, puzzleId]);


    const goToNextTask = () => {
        if (!(taskId && url)) return;
        history.replace(`${url.split('/').slice(undefined, -1).join('/')}/${parseInt(taskId) + 1}`);
    }

    const goToOverview = useCallback(() => {
        if (!(postId && url && history)) return;
        history.goBack();
    }, [history, url, postId]);


    const resetScoreAndGoHome = useCallback(() => {
        PersistentStore.deleteCurrentScore(parseInt(puzzleId));
        PersistentStore.delete('hash');
        window.location.replace('/');
    }, [puzzleId]);


    const goToResults = () => {
        if (!(url)) return;
        history.replace(`${url.split('/').splice(0, 3).join('/')}/results`);
    }


    const onFinishTaskHandler = (score: number, skipCard?: boolean) => {
        const _score = hintUsed ? (score / 2) : score;
        registerTask && registerTask(currentPuzzle?.id, currentPost?.id, parseInt(taskId) - 1, _score);
        setScore(_score);
        if (skipCard) nextHandler();
    }

    const nextHandler = (e?: any) => {
        // TODO POINT HANDLING
        setScore(null);
        if (haveNextTask) {
            goToNextTask();
        } else if (haveRemainingPosts) {
            goToOverview();
        } else {
            goToResults();
        }
    }

    const activateHint = () => {
        setHintActive(true);
        setHintUsed(true);
    }

    const disableHint = () => {
        setHintActive(false);
    }

    const skipTask = () => {
        onFinishTaskHandler(0, isPersonalityTest);
        nextHandler();
    }

    useEffect(() => {
        if (checkedForValidPost || typeof isValidPost !== 'boolean' || typeof invalidScoreCard !== 'boolean') return;
        if (invalidScoreCard) {
            resetScoreAndGoHome();
        }
        if (!isValidPost && !isCompleted) {
            goToOverview && goToOverview();
        }
        setCheckedForValidPost(true);
    }, [isValidPost, isCompleted, goToOverview, checkedForValidPost, invalidScoreCard, resetScoreAndGoHome])

    return (
        <Container bgColor={bgColor}>
            {hintActive &&
            <HintOverlay>
                <ShadowContainer>
                    <HintBox onClick={disableHint}>
                        <h5>Hint</h5>
                        <p>{task?.hint?.text}</p>
                        <Button color={Color.WHITE} bgColor={Color.PURPLE} onClick={disableHint} label="Fortsett"/>
                    </HintBox>
                </ShadowContainer>
            </HintOverlay>
            }
            <Header backButtonStyle="home" type="score" score={highScoreScore} onCloseClick={toggleCloseMenu}/>
            <PageHeader>
                {!isContext ?
                    <h1>Oppgave {`${realTaskId}/${numberOfRealTasks}`}</h1>
                    : null}
            </PageHeader>
            <Content>
                {isCompleted ? <>Du har fullført oppgaven</> :
                    <TaskModule task={task} onFinish={onFinishTaskHandler} skip={skipTask}/>
                }
            </Content>
            <Content className="buttons">
                {!isCompleted && !isContext && !isPersonalityTest &&
                <div className="labels">
                    {
                        task?.hint?.activated && !hintUsed && <LabelButton onClick={activateHint} label={"få et hint!"}/>
                    }
                    <LabelButton onClick={skipTask} label={"hopp over"}/>
                </div>
                }
                {(isCompleted || isContext) && haveNextTask ?
                    <Button size="large" bgColor={Color.GREEN} onClick={goToNextTask} label="Gå videre"/>
                    : (isCompleted || isContext) && haveRemainingPosts ?
                        <Button size="large" bgColor={Color.GREEN} onClick={goToOverview} label="Gå videre"/>
                        : (isCompleted || isContext) ?
                            <Button size="large" bgColor={Color.GREEN} onClick={goToResults} label="Fullfør"/>
                            : null
                }
            </Content>
            {typeof score === 'number' ?
                <ResultOverlay onClick={nextHandler}>
                    <ResultCard score={score} onClick={nextHandler}/>
                </ResultOverlay>
                : null}
            <Footer fill/>
        </Container>
    );
};

export default Task;