import React, {FC, useCallback, useContext, useMemo} from 'react';
import Card from "../../modules/Card/card";
import styled from "styled-components";
import Header from "../../framework/Header/header";
import Color from "../../../common/classes/Color";
import { useHistory } from 'react-router-dom';
import {BasePageProps, Puzzle} from "../../../common/types";
import {Store} from "../../../Store";
import Position from "../../../common/classes/Position";
import Footer from "../../framework/Footer/footer";


export interface MainProps extends BasePageProps {
}


const Container = styled.main<MainProps>`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${ (props) => props?.bgColor};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;


const ContentContainer = styled.ul`
  margin: 40px auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 30px;
  > li {
    margin-bottom: 25px;
  }
  @media (min-width: 768px) {
    > li {
      margin-right: 25px;  
    }
  }
`;


const Main: FC<MainProps> = ({bgColor, toggleCloseMenu, puzzles}) => {
    const { state } = useContext(Store);
    const { position } = state;
    const history = useHistory();

    const data: Puzzle[] = useMemo( () => {
       return (Array.isArray(puzzles) ? puzzles : []).map( (puzzle: Puzzle) => {
           puzzle.distance = Position.positionsToDistanceString(position, puzzle?.posts?.[0].position);
           puzzle.distanceInMeters = Position.calcDistance(position, puzzle?.posts?.[0].position);
           return puzzle;
       }).sort( (a, b) => {
           if (a?.distanceInMeters && b.distanceInMeters){
               return a.distanceInMeters > b.distanceInMeters ? 1 : -1;
           }
           return 0;
       });
    }, [puzzles, position])


    const goToPuzzle = useCallback( (_id) => {
        if (!history) return;
        history.push(`/skattejakt/${_id}`)
    }, [history]);

    return (
        <Container bgColor={bgColor}>
            <Header loginButton onCloseClick={toggleCloseMenu}>
            </Header>
            <ContentContainer>
                { data.map( (x: any) => {
                    return(<Card onClick={() => {goToPuzzle( x ? x?.id : null)}} key={x.title} bgColor={Color.YELLOW} {...x}/>)}
                )}
            </ContentContainer>
            <Footer fill/>
        </Container>
    )
};

export default Main;