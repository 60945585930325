import styled from "styled-components";
import {GeolocationStatus} from "../../../common/types";
import useStore from "../../hooks/useStore";
import {useRef} from "react";

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 1em 1.5em;
  font-size: 1rem;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #0074D9;
  border-bottom: 1px solid rgba(0,0,0,0.3);
  animation: slideIn 500ms ease-in-out forwards;
  @keyframes slideIn {
    0% { transform: translateY(-100%)}
    100% { transform: translateY(0)}
  }
`;


const Button = styled.div`
    background: #000088;
  padding: 0.5em 1em;
  border-radius: 0.25em;
  font-weight: 700;
  margin: 0 1em;
  border: 2px solid rgba(255,255,255,0.7);
  cursor: pointer;
`;

const StatusToLabelMapper: { [key in GeolocationStatus]: string } = {
    [GeolocationStatus.SUCCESS]: "GPS-lokasjon uthentet.",
    [GeolocationStatus.PERMISSION_DENIED]: "Mangler tilgang til din GPS-lokasjon. Vennligst sjekk innstillingene.",
    [GeolocationStatus.POSITION_UNAVAILABLE]: "Kunne ikke hente GPS-lokasjon. Foreløpig utilgjengelig.",
    [GeolocationStatus.TIMEOUT]: "Kunne ikke hente GPS-lokasjon før tidskontrollen gikk ut.",
}

const GeolocationNotice = () => {
    const {state, dispatch} = useStore();
    const dispatchRef = useRef(dispatch);
    const toggled = state.geolocation?.status && state.geolocation.status > GeolocationStatus.SUCCESS && state.geolocation?.noticeEnabled;

    const removeNoticeFromSession = () => {
        dispatchRef.current({
            type: "SET_GEOLOCATION",
            payload: {
                ...state?.geolocation,
                noticeEnabled: false
            }
        })
    }

    return (
        <>
            {
                toggled &&
                <Container>
                        {state.geolocation?.status  && StatusToLabelMapper[state.geolocation.status]}
                    <Button onClick={removeNoticeFromSession}>Lukk</Button>
                </Container>
            }

        </>
    )
}
;

export default GeolocationNotice;