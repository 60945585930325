import React, {useMemo} from 'react';
import styled from "styled-components";
import Button from "../Button/button";
import useStore from "../../hooks/useStore";

export interface ShareProps {

}

const Container = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  background: white;
  align-items: center;
  padding: 20px;
  border: 2px solid #000000;
  box-sizing: border-box;
  box-shadow: 0 6px 0 #000000;
  border-radius: 16px;
`;

const Title = styled.h2`
  font-weight: 900;
  font-size: 1em;
  line-height: 135%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 18px;
`;

const ButtonContainer = styled.div`
  display: flex;
  button {
    text-transform: capitalize;  
  }
  button:nth-child(n+1){
    margin-left: 2px;
  }
`;

const Share: React.FC = () => {
    const { state } = useStore();
    const goToUrl = (_url: string) => {
        window.open(_url, '_blank');
    }

    const url: string = useMemo( () => {
        try{
            return `${window.location.host}/share?userId=${state?.user?.id ?? ''}`;
        } catch (err){
            return  window.location.host;
        }
    }, [state]);

    const facebookClickHandler = () => {
        goToUrl(`https://www.facebook.com/sharer/sharer.php?u=` + url);
    }

    const twitterClickHandler = () => {
        goToUrl(`https://www.twitter.com/share?url=` + url);
    }

    return (
            <Container>
                <Title>Del på sosiale medier</Title>
                <ButtonContainer>
                    <Button icon="facebook" label={'Facebook'} color="#FFFFFF" bgColor="#3B5998" onClick={facebookClickHandler}/>
                    <Button icon="twitter" label={'Twitter'} color="#FFFFFF" bgColor="#1DA1F2" onClick={twitterClickHandler}/>
                </ButtonContainer>
            </Container>
    );
};

export default Share;