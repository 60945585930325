import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import history from "./history";
import './App.css';
import Loading from "./components/framework/Loading/loading";
import Color from "./common/classes/Color";
import useFetchPuzzle from "./components/hooks/useFetchPuzzle";
import {clearInterval, setInterval} from "timers";
import CloseOverlay from "./components/framework/CloseOverlay/closeoverlay";
import styled from "styled-components";
import useScoreCard from "./components/hooks/useScoreCard";
import usePosition from "./components/hooks/usePosition";
import {Store} from "./Store";
import Routes from "./routes";
import GeolocationNotice from "./components/modules/GeolocationNotice/geolocationnotice";


const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const App = () => {
    usePosition();
    const {dispatch} = useContext(Store);
    const [closeMenuOpen, setCloseMenuOpen] = useState<boolean>(false);
    const puzzles = useFetchPuzzle();
    const {scoreCard, registerTask, updateCard} = useScoreCard(puzzles);
    const dispatchRef = useRef(dispatch);

    const toggleCloseMenu = useCallback(() => {
        setCloseMenuOpen((prev) => !prev);
    }, [])

    const pageProps = useMemo(() => {
        return {
            puzzles: puzzles ? puzzles : {},
            toggleCloseMenu,
            scoreCard,
            registerTask,
            updateCard
        }
    }, [puzzles, toggleCloseMenu, scoreCard, registerTask, updateCard])

    const closeMenu = useCallback(() => {
        setCloseMenuOpen(false);
    }, []);

    const onClose = useCallback(() => {
        window.location.href = 'https://www.kulturpunkt.org';
    }, [])

    useEffect( () => {
        dispatchRef.current?.(
            {type: 'SET_PUZZLES', payload: puzzles}
        )
    }, [puzzles]);

    useEffect( () => {
       dispatchRef.current?.(
           {type: 'SET_SCORECARD', payload: scoreCard}
       )
    }, [scoreCard])

    useEffect(() => {
        document.title = 'Skattejakt';
        return () => {
            document.title = '';
        }
    }, [])

    return (
        <div className="App">
            { !puzzles
                ? <Loading bgColor={Color.PURPLE}/> :
                <Router history={history}>
                    <GeolocationNotice/>
                    <Switch>
                        <Route path="*">
                            <Routes {...pageProps}/>
                        </Route>
                    </Switch>
                </Router>
            }
            {
                closeMenuOpen ?
                    <Overlay>
                        <CloseOverlay onClose={onClose} onStay={closeMenu}/>
                    </Overlay> : null
            }
        </div>
    );
}

export default App;
