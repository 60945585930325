import {Position as PositionType, Puzzle} from "../types";

class Position {
    static calcDistance = (pos1?: PositionType, pos2?: PositionType): number | undefined => {
        if (!(pos1 && pos2)) return undefined;
        const {latitude: lat1, longitude: lng1} = pos1;
        const {latitude: lat2, longitude: lng2} = pos2;
        if (!(lat1 && lng1 && lat2 && lng2)) return 0;
        let R = 6371e3;
        let phi1 = lat1 * (Math.PI / 180);
        let phi2 = lat2 * (Math.PI / 180);
        let deltaPhi = (lat2 - lat1) * (Math.PI / 180);
        let deltaAlfa = (lng2 - lng1) * (Math.PI / 180);
        let a = Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
            Math.cos(phi1) * Math.cos(phi2) *
            Math.sin(deltaAlfa / 2) * Math.sin(deltaAlfa / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return (R * c);
    }
    static convertMetersToFinalString = (meters?: number): string => {
        if (!meters) return "";
        if (meters > 1000){
            return `${Math.round((meters / 1000) * 10) / 10} km`;
        }
        return `${Math.round(meters * 10) / 10} m`;
    }
    static positionsToDistanceString = (pos1?: PositionType, pos2?: PositionType) => {
        const meters = Position.calcDistance(pos1, pos2);
        return Position.convertMetersToFinalString(meters);
    }
    static calcInaccurateCenter = (positions: PositionType[]): PositionType => {
        if (positions.length === 0) return {latitude: 45, longitude: 45};
        let accLat = 0;
        let accLng = 0;
        for (const position of positions){
           accLat += position?.latitude ?? 0;
           accLng += position?.longitude ?? 0;
        }
        return {latitude: accLat / positions.length, longitude: accLng / positions.length};
    }
    static getAllPostPositions = (puzzle: Puzzle): PositionType[] => {
        const posts = puzzle?.posts ?? [];
        let positions = [];
        for (const post of posts){
            if (post?.position) positions.push(post.position);
        }
        return positions;
    }
    static getStaticGmapPosition = (puzzle: Puzzle | undefined): PositionType | undefined => {
        if (!puzzle) return puzzle;
        const firstPost = puzzle?.posts?.[0]?.position;
        if (puzzle?.sequential && firstPost){
            return firstPost;
        }
        return Position.calcInaccurateCenter(Position.getAllPostPositions(puzzle));
    }
}

export default Position;
