import Api from "./Api";
import {CompetitionEntry, ScorePayload, TimeSpan, User} from "../types";

class SkattejaktService extends Api {
    constructor() {
        super("self");
    }
    getHighScore = async (puzzleId: number, from?: TimeSpan) => {
        return this.get(`/results?puzzleId=${puzzleId}${from ? '&from=' + from : null}`);
    }
    postScore = async (payload: ScorePayload) => {
        return this.post(`/results`, payload);
    }
    updateUser = async (payload: User) => {
        return this.put(`/users/${payload.id}`, payload);
    }
    getUserInfo = async () => {
        return this.get(`/users/userInfo`);
    }
    registerScore = async (entry: CompetitionEntry) => {
        return this.post(`/competitions/${entry.id}/register`, entry);
    }
}

export default SkattejaktService;