import {StoreAction} from "./common/types";
import {StoreState} from "./Store";

const Reducer = (state: StoreState, action: StoreAction) => {
    switch (action.type){
        case 'SET_GEOLOCATION':
            return {...state, geolocation: action.payload };
        case 'SET_POSITION':
            return {...state, position: action.payload };
        case 'SET_DEBUG':
            return {...state, debug: action.payload };
        case 'SET_PUZZLES':
            return {...state, puzzles: action.payload };
        case 'SET_SCORECARD':
            return {...state, scoreCard: action.payload };
        case 'SET_USER':
            return {...state, user: action.payload };
        default:
            return state;
    }
}

export default Reducer;