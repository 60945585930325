import React from 'react';
import styled from "styled-components";

export interface ShadowContainerProps {
    shadowLength?: number;
    circle?: boolean;
}

const Container = styled.div<ShadowContainerProps>`
  border: 2px solid #000;
  box-shadow: 0 ${({shadowLength}) => shadowLength}px 0 0 #000;
  border-radius: ${({circle}) => circle ? '50%' : '20px'};
  display: flex;
  overflow: hidden;
  flex-direction: column;

  > h2 {
    font-size: 1.2em;
    font-weight: 800;
  }
`;

const ShadowContainer: React.FC<ShadowContainerProps> = ({shadowLength=12,
                                                             circle=false,
                                                             children}) => {
    return (
        <Container circle={circle} shadowLength={shadowLength}>
            {children}
        </Container>
    );
};

export default ShadowContainer;
