import React from 'react';
import {PersonalityTask, QuizTask, TaskContext, WordGuessTask} from "../../../common/types";
import Context from "./__types__/Context/context";
import WordGuess from "./__types__/WordGuess/wordguess";
import Quiz from "./__types__/Quiz/quiz";
import PersonalityTest from "./__types__/PersonalityTest/personlitytest";

export interface TaskProps {
   task?: WordGuessTask | PersonalityTask | QuizTask | TaskContext;
   onFinish?: (score: number) => void;
   skip?: () => void;
}

const Task: React.FC<TaskProps> = (props) => {

    const renderTask = (type?: string) => {
        switch (type){
            case 'context':
                return <Context {...props}/>;
            case 'wordGuess':
                return <WordGuess {...props}/>;
            case 'personalityTest':
                return <PersonalityTest {...props}/>;
            case 'quiz':
                return <Quiz {...props}/>
            default:
                return <>MISSING</>;
        }
    }

    return (
        <>
            { renderTask(props?.task?.taskType)}
        </>
    );
};

export default Task;