import {FC} from "react";
import styled from "styled-components";


export interface SkipLabelProps {
    label?: string;
    onClick?: () => void;
}

const Container = styled.h5`
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 0.45pt;
  line-height: 1.25em;
  text-align: center;
  cursor: pointer;
`;

const Labelbutton: FC<SkipLabelProps> = ({label, onClick}) => {
    return (<Container onClick={onClick}>
        - {label ?? "hopp over"} -
    </Container>)
}

export default Labelbutton;