import React, {useEffect, useMemo, useState} from 'react';
import {IndexIdentifiers, PersonalityTask, PlayerAnswer} from "../../../../../common/types";
import styled from "styled-components";
import Button from "../../../Button/button";
import Color from "../../../../../common/classes/Color";
import placeholderMedia2 from "../../../../../assets/img/placeholder_media2.jpg";
import ShadowContainer from "../../../../framework/ShadowContainer/shadowcontainer";
import LabelButton from '../../../LabelButton/labelbutton';
import Media from "../../../Media/media";
import { Media as MediaType } from "../../../../../common/types";

export interface PersonalityTestProps {
    task?: PersonalityTask;
    onFinish?: (score: number, skipCard: boolean) => void;
    skip?: () => void;
}

let tempCurQuestionIdx: {};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  margin-bottom: 30px;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
`;

const SubTitle = styled.h2`
  background: #FFF;
  padding: 20px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;

const ResultText = styled.p`
  width: 311px;
  background: #FFF;
  padding: 1.375em 1.8125em 1.8125em 1.8125em;
  box-sizing: border-box;
  text-align: left;
`;

const ResultTitle = styled.h5`
  > span {
    font-size: 1.125rem;
    line-height: 1.25em;
    letter-spacing: 0;
  }
  font-size: 1.5625rem;
  font-weight: 900;
  line-height: 1.25em;
`;

const ResultBody = styled.p`
    font-size: 1.125rem;
    margin-top: 2em;
  font-weight: 900;
`;

const Content = styled.div`
  width: 311px;
  display: flex;
  flex-direction: column;
  
  &.body {
    transform: translateY(-4em);
  }
  &.buttons.result{
    margin-top: 10px;
    transform: translateY(-4em);
  }

  &.taskHeader {
    margin-bottom: 20px;
  }

  &.taskHeader > div {
    flex-direction: column;
    margin-bottom: 25px;
    background: #000;
  }

  &.buttons > div:not(:first-of-type) {
    margin-top: 20px;
  }

  &.buttons {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.buttons button {
    text-transform: revert;
    font-size: 16px;
    text-align: left;
  }

  &.buttons.answers > * {
    align-self: stretch;
    button {
      width: 100%;
    }
  }

  &.buttons h4 {
    font-weight: 700;
  }

  &.result, &.body {
    margin: 40px 0;
    align-self: normal;
  }

  &.image.circle img {
    height: 311px;
    width: 311px;
  }

  &.taskHeader img {
    width: 100%;
  }
  
  &.label {
    margin-top: 4em;
  }
`;

const randomizeObject = (obj: any) => {
  const answerKeys = Object.keys(obj).sort(() => .5 - Math.random());
  const answerArray = answerKeys.map(key => { return {[key]: obj[key]} })
  let randomizedAnswers = {};
  answerArray.forEach((a, i) => { randomizedAnswers = {...randomizedAnswers, ...answerArray[i]}; });
  return randomizedAnswers;
}

const PersonalityTest: React.FC<PersonalityTestProps> = ({task, onFinish, skip}) => {
    const [curQuestionIdx, setCurQuestionIdx] = useState<number>();
    const [playerAnswers, setPlayerAnswers] = useState<PlayerAnswer>({})
    const [result, setResult] = useState<{ title?: string, body?: string, media?: MediaType }>();
    let answers: object = useMemo(() => {
        if (!(typeof curQuestionIdx === 'number' && task)) return {};
        const _answers = task?.questions?.[curQuestionIdx]?.answers;
        return _answers ? randomizeObject(_answers) : {};
    }, [curQuestionIdx, task])


    const goToNextQuestion = () => {
        if (typeof curQuestionIdx === 'number' && task?.questions?.[curQuestionIdx + 1]) {
            setCurQuestionIdx((cur) => typeof cur === 'number' ? cur + 1 : 0);
        }
    }
    const qSelectHandler = (key: string) => {
        setPlayerAnswers((prev) => {
            let tmp = {...prev};
            if (key in tmp) {
                tmp[key] += 1;
                return tmp;
            }
            tmp[key] = 1;
            return tmp;
        })
        goToNextQuestion();
    }

    useEffect(() => {
        if (!task) return;
        if (task?.questions?.[0].answers) {
            setCurQuestionIdx(0);
            const {answers: _answers} = task.questions[0];
            let initializePlayerAnswers: PlayerAnswer = {};
            for (let key of Object.keys(_answers)) {
                initializePlayerAnswers[key] = 0;
            }
            setPlayerAnswers(initializePlayerAnswers);
            window.scrollTo(0, 0);
        }
    }, [task]);

    useEffect( () => {
        if (!(task?.questions && task.questions.length > 0 && Object.values(playerAnswers).length > 0)) return;
        const numOfQuestions = Object.entries(task?.questions ?? []).length;
        const answerCount = Object.values(playerAnswers ?? []).reduce( (acc, val) => acc + val);
        console.log(answerCount, numOfQuestions);
        if (answerCount >= numOfQuestions) {
            if (!task?.summary) return;
            const winningLetter = Object.entries(playerAnswers).reduce((item, last) => item[1] > last[1] ? item : last)[0];
            console.log(winningLetter);
            const res = task && winningLetter in task?.summary ? task.summary[winningLetter as IndexIdentifiers] : {};
            setResult({
                title: res?.title ?? '',
                body: res?.body ?? '',
                media: res?.media
            })
        };
    }, [playerAnswers, task]);

    return (
        <Container>
            {result ?
                <>
                    <Content className={"image circle"}>
                        <ShadowContainer circle shadowLength={6}>
                            {result?.media ?
                                <Media {...result?.media}
                                       sizes={{small: '100vw', medium: '50vw', large: '25vw'}}
                                       alt="hvem er du?"/>
                                : <img src={placeholderMedia2} alt="hvem er du?"/>}
                        </ShadowContainer>
                    </Content>
                    <Content className="body">
                        <ShadowContainer>
                            <ResultText>
                                <ResultTitle>
                                    <span>{"Du ble\n"}</span>
                                    { result?.title || ''}
                                </ResultTitle>
                                <ResultBody>
                                    {result?.body || ''}
                                </ResultBody>
                            </ResultText>
                        </ShadowContainer>
                    </Content>
                    <Content className="buttons result">
                        <Button size="large" label="Gå videre" bgColor={Color.GREEN}
                                onClick={() => onFinish?.(10, true)}/>
                    </Content>
                </>
                :
                <>
                    <Content className="title">
                        <Title>{task?.title}</Title>
                    </Content>
                    <Content className="taskHeader">
                        <ShadowContainer>
                            {typeof curQuestionIdx === 'number' && task?.questions?.[curQuestionIdx]?.media ?
                                <Media {...task.questions[curQuestionIdx].media}
                                       sizes={{small: '100vw', medium: '50vw', large: '25vw'}}
                                       alt=""/>
                                : <img src={placeholderMedia2} alt=""/>}
                            {typeof curQuestionIdx === 'number' && task?.questions?.[curQuestionIdx]?.question ?
                                <SubTitle>{task.questions[curQuestionIdx].question}</SubTitle>
                                : null}
                        </ShadowContainer>
                    </Content>
                    <Content className="buttons answers">
                        {Object.entries(answers).map(([key, value], i) => {
                            return <Button onClick={() => {
                                qSelectHandler(key)
                            }} label={value.answer} key={i + '-' + value} bgColor={Color.PURPLE} color={Color.WHITE}/>;
                        })}
                    </Content>
                    <Content className="label">
                        <LabelButton label="hopp over" onClick={skip}/>
                    </Content>
                </>}
        </Container>
    );
};

export default PersonalityTest;