import Api from "./Api";
import {Media, Post, Puzzle, TaskType, VideoSource} from "../types";

class MainService extends Api {

    constructor() {
        super('kulturio');
    }

    getAllPuzzles = async () => {
        return this.get(`/search?q=documentType:rebus AND status:publish`);
    }
    test = async () => {
        return this.getWithResponse(`/search?q=documentType:rebus&status=publish`);
    }
    getPost = async (postId: string) => {
        return this.get(`/documents/${postId}`);
    }
    getAllPuzzlesWithPosts = async () => {
        const res = await this.getAllPuzzles();
        let puzzles: any[] = this.parseModels(res);
        let postIds: string[] = [];
        let posts = [];
        puzzles = this.mergeContentAndData(puzzles, ['title', 'description', 'targetGroup', 'duration', 'location']);
        for (let puzzle of Object.values(puzzles)) {
            puzzle.media = this.getMedia(puzzle, 'image');
            let finished = {...puzzle.finished};
            puzzle.finished.title = this.getLocaleString(finished, 'no', 'title');
            puzzle.finished.body = this.getLocaleString(finished, 'no', 'body');
            let ids: string[] = puzzle.posts?.map((post: any) => {
                return post.referenceId;
            })
            postIds = postIds.concat(ids);
        }
        posts = await this.getFormattedPosts(postIds);
        this.injectPostsIntoPuzzles(puzzles, posts);
        return puzzles;
    }
    getFormattedPosts = async (postIds: string[]) => {
        const data: any = await Promise.all(
            postIds.map((id: string) => {
                return this.getPost(id)
            })
        );
        return data.map((_data: any) => {
            return this.parsePosts(_data);
        })
    }
    parseModels = (data: any): any[] => {
        try {
            if (!data?.models) throw Error("Can't parse services.models from data");
            return data?.models ? data.models : [];
        } catch (err) {
            console.warn(err);
            return []
        }
    }
    parsePosts = (data: any): Post | void => {
        try {
            if (!data?.content) throw Error("Can't parse posts from data");
            // hacking a hacked solution
            data.content.media = this.getMedia(data.content, 'image');
            data?.content?.tasks?.forEach((task: any) => {
                task.media = this.getMedia(task, 'media');
                this.reorganizeTask(task);
            })
            return this.mergeContentAndData(data, ['title', 'description']);
        } catch (err) {
            console.log(err);
            return;
        }
    }
    mergeContentAndData = (data: any[] | any, attributes: string[]) => {
        const destruct = (obj: any) => {
            let _payload: any = {};
            attributes.forEach((attr: string) => {
                _payload[attr] = this.getLocaleString(obj, 'no', attr, 'content');
            })
            let tmp = {...obj, ...obj.content, ..._payload};
            delete tmp.content;
            return tmp;
        };
        if (typeof data !== 'object') return null;
        return Array.isArray(data) ? data.map(destruct) : destruct(data);
    }
    injectPostsIntoPuzzles = (puzzles: Puzzle[], posts: Post[]): Puzzle[] => {
        puzzles.forEach((puzz: Puzzle) => {
            puzz.posts = puzz?.posts?.map((post: Post) => {
                return posts.find((_post) => _post.uniqueId === post?.referenceId && _post?.status === 'publish');
            }).filter((x: any) => x?.id);
        });
        return puzzles.filter((puzzle: Puzzle) => puzzle && puzzle.posts);
    }
    getLocaleString = (obj: any, loc: string, attr: string, targetAttr?: string): string => {
        try {
            let _str = targetAttr ? obj?.[targetAttr]?.[attr]?.['locale:' + loc] : obj?.[attr]?.['locale:' + loc];
            if (!_str && attr in obj) _str = obj[attr];
            if (typeof _str !== 'string') throw Error("Can't parse locale from object");
            return _str;
        } catch (err) {
            //console.warn(err, obj);
            return '';
        }
    }
    getMedia = (obj: any, attr?: string): Media | undefined => {
        try {
            const media = attr ? obj?.[attr]?.media : obj?.media;
            if (media.mediaType === 'image') {
                return {image_src: media?.mediaUrl, type: 'image'};
            }
            if (media.mediaType === 'video') {
                let sources: VideoSource[] = [];
                Object.values(media?.resources ?? {}).forEach((value: any) => {
                    const {url, mimeType} = value;
                    if (!(url && mimeType)) return undefined;
                    sources.push({
                        src: url,
                        type: mimeType
                    })
                })
                return {
                    type: 'video',
                    video_src: sources,
                    thumbnail: media.imageUrl
                }
            }
            return undefined;
        } catch (err) {
            console.warn("Could not extract media url from obj", obj);
            return undefined;
        }
    }
    reorganizeTask = (task: any) => {
        const setHint = () => {
            if (task?.hint && typeof task?.hint?.activated === 'boolean') {
                task.hint = {
                    activated: task.hint.activated,
                    text: this.getLocaleString(task.hint, 'no', 'text')

                }
            }
        }
        const buildPersonalityTest = (task: any) => {
            const reorganizeObj = (data: any) => {
                const setData = (key: string) => {
                    return key.includes('-image-') ? this.getMedia(data[key]) : this.getLocaleString(data, 'no', key);
                }
                let obj: any = {};
                for (let key of Object.keys(data)) {
                    if (!key.includes('-')) {
                        obj[key] = data[key];
                        continue;
                    }
                    let arr = key.split('-');
                    arr.reverse();
                    if (arr?.[0]?.length === 1 && typeof arr?.[1] === 'string') {
                        if (arr[0] in obj) {
                            obj[arr[0]][arr[1]] = setData(key);
                        } else {
                            obj[arr[0]] = {[arr[1]]: setData(key)}
                        }
                    }
                }
                return obj;
            }

            task.title = this.getLocaleString(task, 'no', 'title');
            task.questions.forEach((q: any) => {
                q.question = this.getLocaleString(q, 'no', 'question');
                q.answers = reorganizeObj(q.answers)
                q.media = this.getMedia(q, 'media');
            })
            task.media = this.getMedia(task?.summary, 'image');
            task.summary = reorganizeObj(task.summary);
        }
        const buildWordGuess = (task: any) => {
            task.answer = this.getLocaleString(task, 'no', 'answer');
            task.question = this.getLocaleString(task, 'no', 'question');
            setHint();
        }
        const buildQuiz = (task: any) => {
            task.question = this.getLocaleString(task, 'no', 'question');
            let answers: any[] = [];
            Object.keys(task?.answers).forEach((key: string) => {
                if (!key.includes('-')) return;
                const [type, id] = key.split('-');
                if (type === 'answer' && Object.entries(task.answers[key]).length > 0) {
                    answers.push({
                        answer: this.getLocaleString(task.answers, 'no', key),
                        correct: task.answers['correct-' + id]
                    })
                }

            })
            setHint();
            task.answers = answers;
        }
        const buildContext = (task: any) => {
            task.body = this.getLocaleString(task, 'no', 'body');
            task.title = this.getLocaleString(task, 'no', 'title');
        }
        switch (task?.taskType) {
            case 'personalityTest':
                buildPersonalityTest(task);
                break;
            case 'wordGuess':
                buildWordGuess(task);
                break;
            case 'quiz':
                buildQuiz(task);
                break;
            case 'context':
                buildContext(task);
                break;
            default:
                break;
        }
    }

}

export default MainService;