import {FC} from "react";
import {Media, VideoSource} from "../../../common/types";
import styled from "styled-components";

export interface VideoProps extends Media {


}

const Container = styled.video`
    width: 80vw;
    height: auto;
`;

const Video: FC<VideoProps> = (props) => {
    const { video_src: src } = props;

    return (
        <Container controls>
            { (Array.isArray(src) ? src : []).map((source: VideoSource) => <source src={source.src} type={source.type}/>)}
            Your browser does not support the video tag.
        </Container>
    )
};

export default Video;