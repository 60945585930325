import React, {FC} from 'react';
import styled from "styled-components";
import Icon from "../Icon/icon";


export interface DistanceProps {
    value?: string;
}

const Container = styled.div`;
  flex: 0 0 auto;
  border: 1px solid #000;
  border-radius: 12px;
  font-size: .66em;
  font-weight: 900;
  line-height: 140%;
  padding: 3px 9px;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex; 
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  margin-left: 5px;  
  white-space: nowrap;
`;

const Distance: FC<DistanceProps> = ({value}) => {
    return(
        <Container>
            <Icon icon={'gps'}/>
            <Content>
                {value}
            </Content>
        </Container>
    );
};

export default Distance;