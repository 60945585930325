import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Header from "../../framework/Header/header";
import Footer from "../../framework/Footer/footer";
import {BasePageProps} from "../../../common/types";
import Button from "../../modules/Button/button";
import {useHistory} from "react-router-dom";
import useQuery from "../../hooks/useQuery";

export interface PageNotFoundProps extends BasePageProps {

}

const Container = styled.main<{ bgColor?: string }>`
  background: ${({bgColor}) => bgColor};
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;


const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  margin: 30px 0;
  display: flex;
  align-items: center;

`;

const Description = styled.p`
  font-weight: 900;
  font-size: 14px;
  display: flex;
  align-items: center;

  > div {
    margin: 0 15px;
  }

`;

export enum ErrorCodes {
    SkattejaktNotFound,
    PostNotFound,
    TaskNotFound
}


const CodeToMessage = {
    [ErrorCodes.SkattejaktNotFound]: "Kunne ikke finne skattejakten",
    [ErrorCodes.PostNotFound]: "Kunne ikke finne posten",
    [ErrorCodes.TaskNotFound]: "Kunne ikke finne oppgaven",
}


const PageNotFound: React.FC<PageNotFoundProps> = ({bgColor, toggleCloseMenu}) => {
    const history = useHistory();
    const q = useQuery();
    const [errorCode, setErrorCode] = useState<ErrorCodes>();

    useEffect(() => {
        let code = q.get('errorCode');
        if (typeof code !== 'string') return;
        let codeAsNum = parseInt(code);
        if (Object.values(ErrorCodes).includes(codeAsNum)){
            setErrorCode(codeAsNum);
        }
    }, [q])

    return (
        <Container bgColor={bgColor}>
            <Header onCloseClick={toggleCloseMenu}/>
            <Content>
                <Title>
                    { typeof errorCode === 'number' ? CodeToMessage?.[errorCode] : 'Fant ikke siden'}
                </Title>
                <Description>
                    <Button label="Gå tilbake" onClick={() => {
                        history.goBack()
                    }}/>
                    eller <Button label="Start på nytt" onClick={() => {
                    history.replace('/')
                }}/>
                </Description>
            </Content>
            <Footer fill/>
        </Container>
    )
};

export default PageNotFound;
