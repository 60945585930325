import {FC} from "react";
import {Media as MediaType} from "../../../common/types";
import Video from "../Video/video";
import Image from "../Image/image";

export interface MediaProps extends MediaType {

}

const Media: FC<MediaProps> = (props) => {
    const { type } = props;

    const renderMedia = (): JSX.Element => {
        if (type === 'video') return (<Video {...props} />);
        return <Image {...props}/>;
    }

    return(
            <div>
            { renderMedia() }
            </div>
    )
}

export default Media;