import {FC, useEffect} from 'react';
import { logout } from "@kulturit/authentication";
import {useHistory} from "react-router-dom";
import Settings from "../../../common/Settings";

const Logout: FC = () => {
    const history = useHistory();

    useEffect( () => {
        if (!history) return;
        logout({
            signal: null,
            onLogout: () => {
                history.replace('/');
            },
            apiGateway: Settings.getApiGatewayUrl()
        })
    }, [history])

    return(
        <div>
            logging out...
        </div>
    )
}

export default Logout;