import {createContext, FC, useReducer} from "react";
import Reducer from "./Reducer";
import {Geolocation, Position, Puzzle, ScoreCard, StoreAction, User} from "./common/types";


export interface StoreState {
    geolocation?: Geolocation;
    position?: Position;
    puzzles?: Puzzle[];
    scoreCard?: ScoreCard;
    user?: User | null;
    debug: string;
}

export const initialState: any = {
    geolocation: {
        status: null,
        noticeEnabled: true
    },
    position: null,
    puzzles: [],
    scoreCard: null,
    user: null,
    debug: ""
}


export const Store = createContext<{state: StoreState, dispatch: (data: StoreAction) => void}>(initialState);

const StoreProvider: FC = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return(
        <Store.Provider value={{state,dispatch}}>
            {children}
        </Store.Provider>
    )
}

export default StoreProvider;