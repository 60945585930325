import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {BasePageProps, Puzzle} from "../../../../common/types";
import styled from "styled-components";
import Header from "../../../framework/Header/header";
import Button from "../../../modules/Button/button";
import Color from "../../../../common/classes/Color";
import Share from "../../../modules/Share/share";
import ResultForm from "../../../modules/ResultForm/resultform";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import PersistentStore from "../../../../common/classes/PersistentStore";
import Score from "../../../../common/classes/Score";
import Footer from "../../../framework/Footer/footer";
import SkattejaktService from "../../../../common/core/SkattejaktService";
import {Store} from '../../../../Store';

export interface ResultProps extends BasePageProps {

}

const Container = styled.main<ResultProps>`
  height: 100%;
  background: ${({bgColor}) => bgColor ?? '#FFFFFF'};
  display: flex;
  flex-direction: column;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;


const Title = styled.h1`
  margin: 30px 0;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 16px;
  align-self: center;
`;

const Content = styled.section`
  flex: 0 0 auto;
  align-self: center;
  width: 315px;
  display: flex;
  flex-direction: column;

  &:first-of-type {
    margin-top: 10px;
  }

  &:last-of-type {
    margin-bottom: 50px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  > div:not(:first-of-type) {
    margin-left: 5px;
  }
`;

const Result: React.FC<ResultProps> = ({scoreCard, updateCard, ...props}) => {
    const {state} = useContext(Store);
    const history = useHistory();
    const {url} = useRouteMatch();
    const {puzzleId} = useParams<any>();
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    const score = useMemo(() => {
        if (!(scoreCard && url)) return;
        return Score.parseScoreByUrl(scoreCard, url);
    }, [url, scoreCard]);
    const puzzle: Puzzle | undefined = useMemo(() => {
        if (!(puzzleId && state?.puzzles)) return;
        try {
            return state.puzzles.find((puzzle: Puzzle) => puzzle.id === parseInt(puzzleId));
        } catch (err) {
            return;
        }
    }, [puzzleId, state]);
    const [scoreSaved, setScoreSaved] = useState<number>();
    const formActive = !formSubmitted && puzzle?.finished?.competition;

    useEffect(() => {
        if (!(score && url && puzzleId)) return;
        PersistentStore.addNewScoreSheetEntry(parseInt(puzzleId), 'Meg', score);
        if (puzzleId && score) {
            new SkattejaktService().postScore({puzzleId: puzzleId, points: score})
                .then((data: any) => {
                    if (data?.userId) {
                        return;
                    }
                })
        }
        let card = PersistentStore.get('score');
        let pId = parseInt(puzzleId);
        card = Score.resetScoreCardPuzzle(card, pId);
        updateCard?.(card);
        setScoreSaved(score);
    }, [score, url, updateCard, puzzleId])

    const onSubmitHandler = useCallback(() => {
        setScoreSaved(undefined);
        setFormSubmitted(true);
    }, [])

    const returnClickHandler = useCallback(() => {
        history.push("/");
    }, [history])

    const highScoreClickHandler = useCallback(() => {
        history.push(`${url.split('/').splice(0, 3).join('/')}/highscore`);

    }, [history, url])

    return (
        <Container {...props}>
            <Header type="score" score={score} onCloseClick={props?.toggleCloseMenu}/>
            <Title>{scoreSaved ? `Du fikk ${scoreSaved} poeng!` : 'Trykk returner for å spille igjen!'}</Title>
                <Content>
                    <ResultForm onSubmit={onSubmitHandler} formDisabled={!formActive} score={scoreSaved} puzzle={puzzle}
                                title={puzzle?.finished?.title} body={puzzle?.finished?.body}/>
                </Content>
            <Content>
                <Share/>
            </Content>
            <Content>
                <ButtonContainer>
                    <Button icon='returnIcon' label='Returner' bgColor={Color.GREEN} onClick={returnClickHandler}/>
                    <Button icon="highScoreIcon" label='Highscore' bgColor={Color.YELLOW}
                            onClick={highScoreClickHandler}/>
                </ButtonContainer>
            </Content>
            <Footer fill/>
        </Container>
    );
};

export default Result;