import React, {FC, useCallback, useContext, useEffect, useMemo} from 'react';
import styled from "styled-components";
import Header from "../../framework/Header/header";
import Button from "../../modules/Button/button";
import Color from "../../../common/classes/Color";
import useDeviceQuery from "../../hooks/useDeviceQuery";
import Icon from "../../modules/Icon/icon";
import Meta from "../../modules/Meta/meta";
import ShadowContainer from "../../framework/ShadowContainer/shadowcontainer";
import Distance from "../../modules/Distance/distance";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import {BasePageProps, Puzzle} from "../../../common/types";
import Score from "../../../common/classes/Score";
import {Store} from "../../../Store";
import Position from "../../../common/classes/Position";
import Footer from "../../framework/Footer/footer";
import Settings from "../../../common/Settings";
import treasurePng from "../../../assets/img/fig_treasure@3x.png";
import {ErrorCodes} from "../PageNotFound/pagenotfound";
import Media from '../../modules/Media/media';


export interface SkattejaktProps extends BasePageProps {
}

const Container = styled.main<SkattejaktProps>`
  height: 100%;
  background: ${(props) => props?.bgColor};
  display: flex;
  flex-direction: column;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

const Content = styled.div<{ mobile?: boolean }>`
  flex: 0 0 auto;
  align-self: center;
  margin: 2em 0;
  display: flex;
  flex-direction: ${({mobile}) => mobile ? 'column' : 'row'};
  justify-content: center;
  align-items: ${({mobile}) => mobile ? 'center' : 'flex-start'};
  max-width: 1200px;
`;


const Title = styled.h1`
  font-size: 32px;
  margin-bottom: 25px;
`;

const Marker = styled.div<{ bgColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: 2px solid #000;
  background: ${({bgColor}) => bgColor};
`;

const ImageOverlay = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Description = styled.p`
  align-self: flex-start;
  font-size: 1.5em;
  font-weight: 900;
  line-height: 135%;
  margin: 20px 0;
`;
const MetaContainer = styled.div<{ mobile?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-self: ${({mobile}) => mobile ? 'stretch' : 'baseline'};

  > *:not(:first-of-type) {
    margin-left: 20px;
  }
`;

const Caption = styled.div<{ mobile?: boolean }>`
  flex-basis: 100%;
  margin: 25px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: ${({mobile}) => mobile ? 'stretch' : 'baseline'};

  > p {
    margin-right: 15px;
  }

  > p {
    font-weight: 900;
  }
`;


const Section = styled.section<{ mobile?: boolean }>`
  margin: 25px;
  align-items: ${({mobile}) => mobile ? 'center' : 'flex-start'};
  display: flex;
  flex-direction: column;

  &.image img {
    border-radius: 50%;
    height: 300px;
    width: 300px;
    padding: 4px;
    border: 1px solid rgba(0, 0, 0, 0.75);
    background: rgba(255, 255, 255, 0.7);
  }

  :first-of-type {
    margin-top: 45px;
    margin-bottom: 15px;
  }

  :last-of-type {
    margin-bottom: 80px;
  }
`;


const getStaticStyle = (styles: any[]) => {
    let result: string[] = [];
    styles.forEach((v: any, i: any, a: any) => {
        let style = '';
        if (v?.stylers?.length > 0) { // Needs to have a style rule to be valid.
            style += (v.hasOwnProperty('featureType') ? 'feature:' + v.featureType : 'feature:all') + '|';
            style += (v.hasOwnProperty('elementType') ? 'element:' + v.elementType : 'element:all') + '|';
            v.stylers.forEach((val: any, i: any, a: any) => {
                let propertyName = Object.keys(val)[0];
                let propertyVal = val[propertyName].toString().replace('#', '0x');
                style += propertyName + ':' + propertyVal + '|';
            });
        }
        result.push('style=' + encodeURIComponent(style))
    });

    return result.join('&');
}

const Skattejakt: FC<SkattejaktProps> = ({bgColor, toggleCloseMenu, scoreCard}) => {
    const {state} = useContext(Store);
    const {puzzles} = state;
    const isMobile = useDeviceQuery('mobile');
    const {puzzleId} = useParams<any>();
    const {url} = useRouteMatch();
    const history = useHistory();


    const currentPuzzle = useMemo<Puzzle | undefined>(() => {
        if (!(puzzleId && puzzles && puzzles.length > 0)) return undefined;
        const puzzle = puzzles?.find((x) => x.id === parseInt(puzzleId));
        return puzzle ? puzzle : {};
    }, [puzzleId, puzzles])

    const gMapStaticUrl = useMemo(() => {
        const options = {...Settings.GOOGLE_MAP_OPTIONS};
        let styles = getStaticStyle(options.styles);
        let pos = Position.getStaticGmapPosition(currentPuzzle);
        delete options.styles;
        options.center = pos ? pos.latitude + ',' + pos.longitude : 'oslo';
        const params = new URLSearchParams(options).toString() + styles;
        return "https://maps.googleapis.com/maps/api/staticmap?size=450x170&format=png&" + params + "&key=" + Settings.GOOGLE_MAP_API_KEY;
    }, [currentPuzzle])

    const distance: string = useMemo(() => {
        if (!(state?.position && currentPuzzle)) return "";
        const firstPostLocation = currentPuzzle?.posts?.[0]?.position;
        return Position.positionsToDistanceString(state?.position, firstPostLocation);
    }, [state, currentPuzzle])

    const startHandler = useCallback(() => {
        history.push(`/skattejakt/${puzzleId}/overview`)
    }, [history, puzzleId])

    const score = useMemo(() => {
        if (!(scoreCard && url)) return 0;
        return Score.parseScoreByUrl(scoreCard, url);
    }, [scoreCard, url]);


    useEffect( () => {
        if (currentPuzzle && Object.entries(currentPuzzle).length === 0){
            history.push(`/404?errorCode=${ErrorCodes.SkattejaktNotFound}`);
        }
    }, [currentPuzzle, history])

    return (
        <Container bgColor={bgColor}>
            <Header type="score" score={score} onCloseClick={toggleCloseMenu}/>
            <Content mobile={isMobile}>
                <Section className="image">
                    {currentPuzzle?.media?.image_src ?
                        <Media {...currentPuzzle.media} alt={currentPuzzle?.title}/>
                        :
                        <Media image_src={treasurePng} alt="Illustrasjon av en skatt"/>
                    }
                </Section>
                <Section mobile={isMobile}>
                    <Title>
                        {currentPuzzle?.title}
                    </Title>
                    <MetaContainer mobile={isMobile}>
                        <Meta icon="targetGroup" label={currentPuzzle?.targetGroup}/>
                        <Meta icon="timeConsumption" label={currentPuzzle?.duration ?? ''}/>
                    </MetaContainer>
                    <Description>
                        {currentPuzzle?.description}
                    </Description>
                    <div>
                        <ShadowContainer>
                            <Media width={isMobile ? '315px' : '450px'} height="170px" image_src={gMapStaticUrl}>
                                <ImageOverlay>
                                    <Marker bgColor={Color.PURPLE}>
                                        <Icon icon={'markerWhite'}/>
                                    </Marker>
                                </ImageOverlay>
                            </Media>
                        </ShadowContainer>
                    </div>
                    <Caption mobile={isMobile}>
                        { currentPuzzle?.location ?
                            <p>Sted: {currentPuzzle.location} </p>
                            : null}
                        <Distance value={distance}/>
                    </Caption>
                    <Button
                        size="large"
                        color={Color.WHITE}
                        bgColor={Color.PURPLE}
                        label="Start jakten!"
                        onClick={startHandler}
                    />
                </Section>
            </Content>
            <Footer fill/>
        </Container>
    );
};

export default Skattejakt;