import {useEffect, useRef} from "react";
import useStore from "./useStore";


const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
}

const usePosition = () => {
    const {state, dispatch} = useStore();
    const dispatchRef = useRef(dispatch);
    const stateRef = useRef(state);

    useEffect(() => {
        const success = (_position: GeolocationPosition): void => {
            const {latitude, longitude} = _position.coords;
            dispatchRef.current({
                type: "SET_POSITION",
                payload: {
                    latitude,
                    longitude
                }
            });
            dispatchRef.current({
                type: "SET_GEOLOCATION",
                payload: {
                    ...stateRef?.current?.geolocation,
                    status: 0
                }
            })
        }

        const error = (_error: GeolocationPositionError) => {
            dispatchRef.current({
                type: "SET_GEOLOCATION",
                payload: {
                    ...stateRef?.current?.geolocation,
                    status: _error.code
                }
            })
        }
        if (!navigator?.geolocation) return;
        let id = navigator.geolocation.watchPosition(success, error, options);
        return () => {
            navigator.geolocation.clearWatch(id);
        }
    }, [])

};

export default usePosition;