import {PostStatus} from "../components/modules/PostUnit/postunit";

export interface Position {
    latitude?: number,
    longitude?: number
}

export enum GeolocationStatus {
    SUCCESS,
    PERMISSION_DENIED,
    POSITION_UNAVAILABLE,
    TIMEOUT
}

export interface Media extends Image, Video {
    type?: 'video' | 'image';
}

export interface SizesSize {
    large: string;
    medium: string;
    small: string;
}

export interface Video {
    video_src?: VideoSource[];
    thumbnail?: string;
}

export interface Image {
    image_src?: string;
    alt?: string;
    height?: string;
    width?: string;
    sizes?: SizesSize;
}


export interface Video {
    sources?: VideoSource[];
    thumbnail?: string;
}

export interface VideoSource {
    src?: string;
    type?: string;
}

export interface Geolocation {
    noticeEnabled: boolean;
    status?: GeolocationStatus;
}

export type IndexIdentifiers = 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g';

export interface PlayerAnswer {
    [k: string]: number;
}

export type TimeSpan = 'lastWeek' | 'lastYear'

export interface StoreAction {
    payload: any;
    type: string;
}

export type ApiSrc = 'kulturio' | 'self';

export type TaskType = 'wordGuess' | 'context' | 'quiz';

export type LetterType = 'alternative' | 'answer';

export interface ScorePayload {
    points: number;
    puzzleId: number;
}

export interface User {
    displayName: string;
    id: number;
}

export interface QuizAnswer {
    answer?: string;
    correct?: boolean;
}

export interface TaskBase {
    taskType?: TaskType;
    question?: LocaleString;
    media?: Media;
    score?: number;
    hint?: Hint;
}

export interface Hint {
    activated: boolean;
    text: string;
}

export interface ResultFormData {
    name: string;
    email: string;
    number: string;
}

export interface QuizTask extends TaskBase {
    answers?: QuizAnswer[];
}

export interface WordGuessTask extends TaskBase {
    answer?: string;
}

export interface TaskContext extends Pick<TaskBase, 'taskType' | 'media'> {
    body?: string;
    title?: string;
}

export interface PersonalityTask extends TaskBase {
    questions?: {
        answers?: {
            [k in IndexIdentifiers]: {
                answer: string;
            };
        };
        media?: Media;
        question?: string;
        score?: number;
    }[];
    title?: string;
    summary?: {
        [k in IndexIdentifiers]: {
            title?: string;
            media?: Media;
            body?: string;
        }
    };
}

export type LocaleString = {
    [k: string]: string;
}

export interface Post {
    id?: number;
    media?: Media;
    referenceId?: string;
    position?: Position;
    tasks?: Array<TaskContext | QuizTask | WordGuessTask>;
    title?: string;
    status?: string;
    uniqueId?: string;
}

export interface BasePageProps {
    toggleCloseMenu?: () => void;
    bgColor?: string;
    puzzles?: Puzzle[];
    updateCard?: (card: ScoreCard) => void;
    scoreCard?: ScoreCard;
    registerTask?: (puzzleId: number, postId: number, taskIdx: number, score: number) => void;
}

export interface Puzzle {
    id?: number;
    posts?: any[];
    title?: string;
    uniqueId?: string;
    sequential?: string;
    targetGroup?: string;
    location?: Position;
    requirePresence?: boolean;
    status?: string;
    description?: LocaleString;
    duration?: string;
    distance?: string;
    distanceInMeters?: number;
    media?: Media;
    finished?: {
        title?: string;
        body?: string;
        competition?: boolean;
    }
}


export interface TaskScore {
    idx: number;
    isTask: boolean;
    completed: boolean;
}

export interface PostScore {
    id: number;
    status?: PostStatus;
    tasks: TaskScore[]
}

export interface PuzzleScore {
    score: number;
    id: number;
    posts: PostScore[];
}

export interface ScoreCard {
    puzzles: PuzzleScore[];
}

export type StoreTypes = 'score' | 'sheet' | 'jwt' | 'hash';


export interface OverviewPost extends Post {
    scores?: PostScore;
}


export interface ScoreSheet {
    [k: string]: ScoreEntry[];
}

export interface ScoreEntry {
    name?: string;
    score?: number;
}


export interface CompetitionEntry {
    id: number;
    puzzleName: string;
    name: string;
    email: string;
    points: number;
}